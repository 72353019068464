<template>
  <b-modal
    :id="id"
    hide-header
    hide-header-close
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <div class="icon-logo"></div>
      </b-col>
    </b-row>
    <div class="tw-float-right tw-w-full lg:tw-w-4/5">
      <div>
        <h2 class="mb-3 heading tw-text-2xl">
          You are prescribing Estrogen to a patient that has not been prescribed
          Progesterone. <br /><br />Do you want to continue?
        </h2>
      </div>

      <!-- <b-table-simple small responsive>
        <b-thead>
          <b-tr>
            <b-th>Status</b-th>
            <b-th>Date</b-th>
            <b-th>Prescription</b-th>
            <b-th>Dosage</b-th>
            <b-th>Unit</b-th>
            <b-th>Refills</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(prescription, i) in prescriptions"
            :key="i"
            :class="{ 'tw-text-primary': prescription.status === 'New' }"
          >
            <b-th>{{ prescription.status }}</b-th>
            <b-th>{{ parseDate(prescription.date, "MM/dd/yy") }}</b-th>
            <b-td>{{ prescription.prescription }}</b-td>
            <b-td>{{ prescription.dosage }}</b-td>
            <b-td>{{ prescription.unit }}</b-td>
            <b-td>{{ prescription.refills }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple> -->

      <b-row class="my-4">
        <b-col cols="8" class="mb-2">
          <b-button
            block
            pill
            variant="primary"
            @click="$emit('prescribe')"
            :disabled="processing"
          >
            <b-spinner v-if="processing" small></b-spinner>
            <span v-else>Continue</span>
          </b-button>
        </b-col>
        <b-col cols="8">
          <b-button
            block
            pill
            variant="outline-primary"
            @click="$bvModal.hide(id)"
          >
            <span>Cancel</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "prescribe-estrogen-alert",
    },
  },
  methods: {
    parseDate(date, stringFormat) {
      if (date) {
        return format(fromUnixTime(date), stringFormat);
      }
    },
  },
};
</script>
